import { flatten } from 'lodash';
import { formatPercentageValue } from 'src/views/clearing/billingDocuments/BillingDetailsListView/utils';
import { ReportFilterData } from '../../types';
import {
  cellStyles,
  headCellStyles,
  reportInfoCellStyles,
  summaryCellStyles,
  TableConfig,
  tableSemanticElements
} from '../const';
import { TableRow } from '../types';
import { HourlyDeparture } from './types';

export const title = '2. Звіт погодинного випуску автобусів на маршрут';

export const getTableHeadersCount = (report: HourlyDeparture) =>
  2 + report.counteragents[0]?.routes[0]?.timeSpans.length * 2;

export const getTableConfig = (
  filterData: ReportFilterData,
  report: HourlyDeparture
): TableConfig => {
  const times = report.counteragents[0]?.routes[0]?.timeSpans || [];

  const formattedWorkingHours = times.map(
    ({ from, to }) => `з ${from} до ${to}`
  );

  const headersCount = getTableHeadersCount(report);

  return {
    [tableSemanticElements.TITLE]: [
      [
        {
          colSpan: headersCount,
          value: title,
          styles: { ...reportInfoCellStyles, halign: 'center' }
        }
      ]
    ],
    [tableSemanticElements.METADATA]: [
      [
        { value: 'Дата', colSpan: 1, styles: reportInfoCellStyles },
        {
          colSpan: headersCount - 1,
          value: filterData.date,
          styles: reportInfoCellStyles
        }
      ],
      []
    ],
    [tableSemanticElements.HEAD]: [
      [
        {
          rowSpan: 3,
          value: 'Маршрут',
          styles: { ...headCellStyles, cellWidth: 16 }
        },
        {
          colSpan: formattedWorkingHours.length * 2,
          value: 'Години доби',
          styles: headCellStyles
        },
        {
          rowSpan: 3,
          value: 'Випуск на маршрут, %',
          styles: { ...headCellStyles, cellWidth: 12 }
        }
      ] as TableRow,
      formattedWorkingHours.map(value => ({
        value,
        colSpan: 2,
        styles: headCellStyles
      })) as TableRow,
      [
        ...flatten(
          formattedWorkingHours.map(
            (): TableRow => [
              {
                value: 'план',
                styles: headCellStyles
              },
              {
                value: 'факт',
                styles: headCellStyles
              }
            ]
          )
        )
      ]
    ],
    [tableSemanticElements.BODY]: report.counteragents
      .map((counteragent): TableRow[] => [
        [
          {
            value: counteragent.name,
            colSpan: headersCount,
            styles: summaryCellStyles
          }
        ],
        ...counteragent.routes.map(
          (route): TableRow => [
            { value: route.name, styles: cellStyles },
            ...route.timeSpans
              .map(({ planned, fact }) => [
                { value: planned || '-', styles: cellStyles },
                { value: fact || '-', styles: cellStyles }
              ])
              .flat(),
            {
              value: formatPercentageValue(route.ratioPerDay),
              styles: cellStyles
            }
          ]
        ),
        [
          {
            value: `В середньому за день роботи (по перевізнику ${counteragent.name})`,
            colSpan: headersCount - 1,
            styles: {
              ...summaryCellStyles,
              halign: 'left'
            }
          },
          {
            value: formatPercentageValue(counteragent.ratioPerDay),
            styles: summaryCellStyles
          }
        ]
      ])
      .flat()
  };
};
